import "core-js/modules/es.array.slice.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { QuillEditor, Quill } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { request } from "@/utils/request";
import { reactive, onMounted, ref, toRaw, watch } from 'vue';
import { ImageExtend, QuillWatch } from 'quill-image-extend-module';
import ImageResize from 'quill-image-resize-module';
export default {
  __name: 'QuillEditors',
  props: ['value'],
  emits: ['updateValue'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var content = ref('');
    var myQuillEditor = ref();
    var fontSize = ['12px', '13px', '14px', '15px', '16px', '18px', '20px', '24px', '28px', '32px', '36px'];
    Quill.imports['attributors/style/size'].whitelist = fontSize;
    Quill.register(Quill.imports['attributors/style/size'], true);
    Quill.register('modules/imageResize', ImageResize);
    Quill.register('modules/ImageExtend', ImageExtend); // 通过watch监听回显，笔者这边使用v-model:content 不能正常回显
    // watch(() => props.value, (val) => {
    //     toRaw(myQuillEditor.value).setHTML(val)
    // }, { deep: true })

    var fileBtn = ref();
    var data = reactive({
      content: props.value,
      editorOption: {
        modules: {
          imageResize: {
            // 添加
            displayStyles: {
              // 添加
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'] // 添加

          },
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{
            'size': ['small', false, 'large', 'huge']
          }], [{
            'font': []
          }], [{
            'align': []
          }], [{
            'list': 'ordered'
          }, {
            'list': 'bullet'
          }], [{
            'indent': '-1'
          }, {
            'indent': '+1'
          }], [{
            'header': 1
          }, {
            'header': 2
          }], ['link', 'image'], [{
            'direction': 'rtl'
          }], [{
            'color': []
          }, {
            'background': []
          }]]
        },
        placeholder: '请输入内容...'
      }
    });

    var imgHandler = function imgHandler(state) {
      if (state) {
        fileBtn.value.click();
      }
    }; // 抛出更改内容，此处避免出错直接使用文档提供的getHTML方法
    // const setValue = () => {
    //     const text = toRaw(myQuillEditor.value).getHTML()
    //
    // }


    var setValue = function setValue() {
      var text = toRaw(myQuillEditor.value).getHTML();
      emit('updateValue', text);
    };

    var handleUpload = function handleUpload(e) {
      var files = Array.prototype.slice.call(e.target.files); // console.log(files, "files")

      if (!files) {
        return;
      }

      var formdata = new FormData();
      formdata.append('file', files[0]);
      var teamAvatarUrl = request.post("/file/upload", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (res) {
        if (res.data) {
          var quill = toRaw(myQuillEditor.value).getQuill();
          var length = quill.getSelection().index;
          quill.insertEmbed(length, 'image', res.data);
          quill.setSelection(length + 1);
        }
      });
    }; // 初始化编辑器


    onMounted(function () {
      // const quill = toRaw(myQuillEditor.value).getQuill()
      //
      // if (myQuillEditor.value) {
      //     quill.getModule('toolbar').addHandler('image', imgHandler)
      // }
      var quill = toRaw(myQuillEditor.value).getQuill();

      if (myQuillEditor.value) {
        quill.getModule('toolbar').addHandler('image', imgHandler);
      }

      toRaw(myQuillEditor.value).setHTML(props.value);
      content.value = props.value;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(QuillEditor), {
        ref_key: "myQuillEditor",
        ref: myQuillEditor,
        theme: "snow",
        modelValue: content.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return content.value = $event;
        }),
        options: data.editorOption,
        contentType: "html",
        "onUpdate:content": _cache[1] || (_cache[1] = function ($event) {
          return setValue();
        })
      }, null, 8, ["modelValue", "options"]), _createElementVNode("input", {
        type: "file",
        hidden: "",
        accept: ".jpg,.png",
        ref_key: "fileBtn",
        ref: fileBtn,
        onChange: handleUpload
      }, null, 544)]);
    };
  }
};